.lc-module {
  &.lc-bikes {
    display: inherit;

    .lc-tab-panel {
      padding: 15px;

      @include font-small;
    }

    .lc-ul-bikes {
      padding: 0px 20px;
      list-style-type: none;
    }

    .lc-bike {
      height: inherit;
      cursor: pointer;
      // padding: 5px;

      padding: 10px 0;

      &.lc-bike-selected {
        border-top: 1px solid $primary;
      }

      .lc-bike-active {
        font-weight: bold;
      }

      .lc-bike-content {
        .lc-bike-available-title,
        .lc-bike-address-title {
          margin-top: 7px;
          font-weight: bold;
        }

        .lc-bike-available {
          display: flex;
          align-items: center;
          padding: 10px 0 5px;

          .lc-loading {
            padding: 0;
            align-items: initial;

            img {
              width: 20px;
              padding: 0;
            }
          }

          .lc-available,
          .lc-capacity {
            display: flex;
            align-items: center;

            img {
              width: 25px;
            }
          }

          .lc-capacity {
            margin-left: 10px;

            img {
              width: 12px;
            }
          }
        }

        .lc-bike-prices {
          margin-top: 5px;
          .lc-bike-prices-title {
            margin-bottom: 3px;
            font-weight: bold;
          }
          ul {
            padding-inline-start: 0px;
            list-style-type: none;
          }
        }

        .lc-bike-price-url {
          margin-top: 5px;
          font-weight: bold;
          a {
            font-weight: normal;
          }
        }
      }
    }

    .lc-legend {
      display: flex;
      align-items: center;
      padding: 5px 0;

      .lc-image {
        display: flex;
      }

      .lc-name {
        margin-left: 15px;
      }
    }
  }
}
